import React from 'react';
import { Modal } from 'antd';

import { ADVERTISER_DISCLOSURE } from '~/utils/Constants';

export default () => {
  const [visible, setVisible] = React.useState(false);
  return (
    <>
      <br />
      <span onClick={() => setVisible(true)} style={{ cursor: 'pointer' }}>
        Advertiser Disclosure
      </span>
      <Modal title="Advertiser Disclosure" visible={visible} onCancel={() => setVisible(false)} footer={null}>
        {ADVERTISER_DISCLOSURE}
      </Modal>
      <style jsx>{`
        span {
          color: #497b89;
          font-size: 12px;
        }
      `}</style>
    </>
  );
};
