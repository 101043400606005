import React from 'react';
import Disclaimer from '~/components/index/Disclaimer';
import AdvertiserDisclosure from '~/components/index/AdvertiserDisclosure';
import { useTranslation } from 'react-i18next';

export default function Fallback() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <section className="fallback">
        <div>
          Check out our other products:
          <br />
          <a href="https://www.salarytics.com" target="_blank">
            Salarytics
          </a>
          &nbsp;|&nbsp;
          <a href="https://instant.1point3acres.com/" target="_blank">
            Instant
          </a>
          &nbsp;|&nbsp;
          <a href="https://jobs.1point3acres.com/" target="_blank">
            JobDuoDuo
          </a>
          <br />
          <a href="https://offer.1point3acres.com/" target="_blank">
            OfferDuoDuo
          </a>
          &nbsp;|&nbsp;
          <a href="https://visa.1point3acres.com/" target="_blank">
            Visa Tracker
          </a>
        </div>
        <p>
          Brought to you with ❤️ from{' '}
          <a href="https://www.1point3acres.com" target="_blank">
            1P3A
          </a>
        </p>
        <p>
          <a href="https://forms.gle/Du7VHgRyt63hijaH8" target="_blank">
            {t('footer.share.help_link')}
          </a>
        </p>
        <p>
          Inspired by{' '}
          <a href="https://github.com/shfshanyue/2019-ncov" target="_blank">
            2019-ncov
          </a>
          &nbsp;&nbsp;
          {process.browser && (
            <a href="https://github.com/phildini/stayinghomeclub" target="_blank">
              stayinghomeclub
            </a>
          )}
        </p>
        <div>
          Icons made by{' '}
          <a href="https://www.flaticon.com/authors/freepik" title="Freepik">
            Freepik
          </a>{' '}
          from{' '}
          <a href="https://www.flaticon.com/" title="Flaticon">
            www.flaticon.com
          </a>
        </div>
        <span>
          <Disclaimer />
          <AdvertiserDisclosure />
          <a className="dns-link" style={{ fontSize: 12, color: '#7e7e7e' }} />
        </span>
      </section>
      <style jsx>{`
        .fallback {
          padding: 0 1rem 6rem;
          color: #999;
          text-align: center;
          font-size: 14px;
        }
        p {
          margin-bottom: 0px;
        }
        a {
          text-decoration: none;
          color: #497b89;
        }
        .boba {
          color: #497b89;
        }
        .flatten-the-curve {
          width: 500px;
          margin-left: auto;
          margin-right: auto;
        }
        @media screen and (max-width: 576px) {
          .flatten-the-curve {
            width: 100%;
          }
        }
      `}</style>
    </>
  );
}
