import React from 'react';
import { withTranslation } from 'react-i18next';
import { Result } from 'antd';
import * as Sentry from '@sentry/node';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, eventId: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (
      Sentry.getCurrentHub()
        ?.getClient()
        ?.getOptions()?.enabled
    ) {
      // when sentry is already initialized
      Sentry.withScope(scope => {
        scope.setExtras(errorInfo);
        const eventId = Sentry.captureException(error);
        this.setState({ eventId });
      });
    }
  }

  render() {
    const { i18n } = this.props;
    if (this.state.hasError) {
      return (
        <Result
          status="warning"
          title={i18n.language === 'zh' ? '加载错误' : 'Something went wrong.'}
          subTitle={
            i18n.language === 'zh' ? (
              <span className="selectable-text">
                如果您持续看到此错误，请通过右下角的聊天窗口联系我们。
                <br />
                {this.state.eventId && `错误代码: ${this.state.eventId}`}
              </span>
            ) : (
              <span className="selectable-text">
                If you constantly see this error, please let us know via the chat button on the bottom right corner.
                <br />
                {this.state.eventId && `Error code: ${this.state.eventId}`}
              </span>
            )
          }
        />
      );
    }

    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);
