import React from 'react';
import classNames from 'classnames';
import { Icon } from 'antd';
import _ from 'lodash';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

import Menu from '~/components/index/Menu';
import ErrorBoundary from '~/components/ErrorBoundary';
import useLocation from '~/hooks/useLocation';
import { toCountryText, FEATURED_REGIONS, getFlagUrl } from '~/utils/LocationHelper';

export default ({ fixed, navItems, regionBannerIndexRange = [-1, -1] }) => {
  const [active, setActive] = React.useState('');
  const [showBackToTop, setShowBackToTop] = React.useState(false);
  const [showMenu, setShowMenu] = React.useState(false);
  const [showRegion, setShowRegion] = React.useState(regionBannerIndexRange[0] >= 0);
  const { t, i18n } = useTranslation();
  const router = useRouter();
  const { country, state, county, setState, setCounty } = useLocation();

  const handleScroll = _.throttle(() => {
    for (let i = 0; i < navItems.length; i++) {
      const id = navItems[i].id;
      const scrollY = window.scrollY + 60;
      try {
        const currentDom = document.querySelector(`#${id}`);
        if (currentDom && scrollY > currentDom.offsetTop && scrollY < currentDom.offsetTop + currentDom.offsetHeight) {
          setActive(id);
          setShowRegion(i >= regionBannerIndexRange[0] && i <= regionBannerIndexRange[1]);
        }
      } catch (err) {}
    }
    setShowBackToTop(window.scrollY > 500);
    try {
      const navContainerDom = document.querySelector(`#nav-container`);
      setShowMenu(navContainerDom && navContainerDom.getBoundingClientRect().top < 10);
      if (navContainerDom && navContainerDom.getBoundingClientRect().top > 10) {
        setShowRegion(false);
      }
    } catch (err) {}
  }, 100);

  const scrollToTop = () => {
    document.body.scrollTop = 0; // Safari
    document.documentElement.scrollTop = 0; // Chrome, Firefox, IE and Opera
  };

  React.useEffect(() => {
    if (!fixed) {
      window.addEventListener('scroll', handleScroll);
      handleScroll();
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  function handleClick(id) {
    if (fixed) {
      window.location.href = `/#${id}`;
    } else {
      window.scrollTo({
        top: document.querySelector(`#${id}`).offsetTop - 40,
      });
      setActive(id);
    }
  }

  const activeItemIndex = _.findIndex(navItems, item => item.id === active);

  return (
    <>
      <ErrorBoundary>
        <div className={classNames('nav-container', { 'nav-container-bordered': showRegion })} id="nav-container">
          {showMenu && (
            <div>
              <Menu sticky />
            </div>
          )}
          <div className="card row nav-items">
            <span className="nav-items-inner">
              {showBackToTop && (
                <span className="item-outer">
                  <Icon type="arrow-up" onClick={scrollToTop} style={{ fontSize: 15, paddingRight: 5 }}></Icon>
                </span>
              )}
              {navItems.map((item, index) => (
                <span
                  key={item.id}
                  className={classNames('item-outer', {
                    active: index === activeItemIndex,
                    'before-active': activeItemIndex >= 0 && index === activeItemIndex - 1,
                    'after-active': activeItemIndex >= 0 && index === activeItemIndex + 1,
                  })}>
                  <span className="item-inner" onClick={() => handleClick(item.id)}>
                    {t(`nav.${item.id}`)}
                  </span>
                </span>
              ))}
            </span>
          </div>
          {showRegion && (
            <div className="card row region-menu">
              <span>
                <span onClick={() => window.dispatchEvent(new CustomEvent('show-region-modal', {}))}>
                  {t('nav.viewing_data_of')}
                  {_.find(FEATURED_REGIONS, r => r.tag === country) && (
                    <>
                      <img src={getFlagUrl(_.find(FEATURED_REGIONS, r => r.tag === country).iso2)} width="18px" />{' '}
                    </>
                  )}
                  {{ us: t('stat.us'), ca: t('stat.ca') }[country] || toCountryText(country, i18n.language)}{' '}
                  <Icon type="setting" className="region-menu-icon" />
                </span>
                {state && (
                  <span
                    onClick={() => {
                      setState('');
                      setCounty('');
                    }}>
                    <Icon type="caret-right" className="region-menu-icon" /> {state}{' '}
                    <Icon type="close-circle" className="region-menu-icon" />
                  </span>
                )}
                {county && (
                  <span onClick={() => setCounty('')}>
                    <Icon type="caret-right" className="region-menu-icon" /> {county}{' '}
                    <Icon type="close-circle" className="region-menu-icon" />
                  </span>
                )}
              </span>
            </div>
          )}
        </div>
      </ErrorBoundary>
      <style jsx>{`
        .nav-container {
          position: sticky;
          top: 0px;
          left: 0px;
          z-index: 100;
          background: #9dbcc5;
          border-radius: 5px;
        }
        .nav-container-bordered {
          border-bottom: 1px solid #efefef;
        }
        .nav-items-inner {
          background: #fff;
          @media screen and (prefers-color-scheme: dark) {
            background: var(--dark-bg-color);
          }
        }
        .card {
          margin-top: 0px;
          margin-bottom: 0px;
          padding-top: 0px;
          padding-bottom: 0px;
        }
        .nav-items {
          padding-top: 2px;
          background: #9dbcc5;
          white-space: nowrap;
          overflow-x: scroll;
          overflow-y: hidden;
          -ms-overflow-style: none;
          scrollbar-width: none;

          @media screen and (min-width: 576px) {
            justify-content: center;
          }
        }
        .nav-items::-webkit-scrollbar {
          display: none;
        }
        .item-outer {
          background: #9dbcc5;
          cursor: pointer;
          padding-top: 6px;
          padding-bottom: 8px;
          line-height: 30px;
        }
        .before-active {
          border-radius: 0px 0px 5px 0px;
        }
        .after-active {
          border-radius: 0px 0px 0px 5px;
        }
        .item-inner {
          font-size: 12px;
          font-weight: bold;
          padding: 4px 10px 8px 10px;
          border-radius: 0px 0px 5px 5px;
        }
        .active {
          span {
            color: #497b89;
            background: #fff;
            border-radius: 5px 5px 0px 0px;
            @media screen and (prefers-color-scheme: dark) {
              background: var(--dark-bg-color);
            }
          }
        }
        @media screen and (max-width: 576px) {
          .nav-items span {
            letter-spacing: -1px;
          }
        }

        .region-menu {
          span {
            margin: auto;
            cursor: pointer;
            font-size: 12px;
            font-weight: bold;
            padding-top: 3px;
            padding-bottom: 7px;
          }
        }

        :global(.region-menu-icon) {
          color: #999999 !important;
        }
      `}</style>
    </>
  );
};
