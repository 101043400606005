import React from 'react';
import _ from 'lodash';
export default () => {
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  const [total, setTotal] = React.useState(null);

  const random = _.random(0, 2);
  React.useEffect(() => {
    setTimeout(() => {
      const initCount = 234990000;
      const initTimeInSeconds = 1591900734;
      const viewsPerSecond = 2; // roughly more than 200000 per 24hrs
      const increased = (Math.floor(Date.now() / 1000) - initTimeInSeconds) * viewsPerSecond;
      const total = numberWithCommas(initCount + increased);
      setTotal(total);
    }, 1000 * random);
  }, []);

  return <span style={{ fontSize: 12 }}>{total} visits</span>;
};
