import React from 'react';
import { Modal } from 'antd';

import { DISCLAIMER } from '~/utils/Constants';

export default () => {
  const [visible, setVisible] = React.useState(false);
  return (
    <>
      <span onClick={() => setVisible(true)} style={{ cursor: 'pointer' }}>
        Disclaimer: Click to Read the Disclaimer
      </span>
      <Modal title="Disclaimer" visible={visible} onCancel={() => setVisible(false)} footer={null}>
        {DISCLAIMER}
      </Modal>
      <style jsx>{`
        span {
          color: #497b89;
          font-size: 12px;
        }
      `}</style>
    </>
  );
};
