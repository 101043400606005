import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import classnames from 'classnames';
import _ from 'lodash';

export default ({ sticky }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const router = useRouter();

  const [menus, setMenus] = React.useState([
    { title: t('nav.home'), icon: 'home' },
    { title: t('nav.test_hospitalization'), icon: 'experiment' },
    { title: t('nav.world'), icon: 'global', promote: true },
    { title: t('nav.job'), icon: 'solution', promote: true },
    { title: t('nav.life'), icon: 'shopping-cart' },
    { title: t('nav.ppe'), icon: 'heart', promote: true },
    { title: t('nav.about'), icon: 'team' },
    {
      title: t('nav.get_our_data'),
      icon: 'database',
    },
    { title: t('nav.support'), icon: 'like' },
  ]);
  const [activeTitle, setActiveTitle] = React.useState(null);

  React.useEffect(() => {
    const menus = [
      { title: t('nav.home'), link: `/${language === 'ppe' ? '' : language}`, icon: 'home' },
      { title: t('nav.test_hospitalization'), link: `/${language}/test`, icon: 'experiment' },
      { title: t('nav.world'), link: `/${language}/world`, icon: 'global', promote: true },
      { title: t('nav.job'), href: 'https://jobs.1point3acres.com', icon: 'solution', promote: true },
      { title: t('nav.life'), link: `/${language}/life`, icon: 'shopping-cart' },
      { title: t('nav.ppe'), link: `/ppe`, icon: 'heart', promote: true },
      { title: t('nav.about'), link: `/${language}/about`, icon: 'team' },
      {
        title: t('nav.get_our_data'),
        icon: 'database',
        link: `/${language}/data`,
      },
      { title: t('nav.support'), link: `/${language}/donate`, icon: 'like' },
    ];
    setMenus(menus);
    setActiveTitle(menus[0].title);
    const pathname = router.pathname || '';
    const activeMenu = _.find(menus, menu => {
      const pathSegments = (menu.link || menu.href || '').split('/');
      return pathSegments && pathSegments.length > 2 && router.pathname.includes(pathSegments[2]);
    });
    if (activeMenu) {
      setActiveTitle(activeMenu.title);
    }
  }, [language]);

  return (
    <>
      <div className={classnames('menu-container', sticky ? 'menu-container-sticky' : 'menu-container-header')}>
        {menus.map(menu => (
          <a key={menu.icon} href={menu.href || menu.link} target={menu.href ? '_blank' : '_self'}>
            <div
              className={classnames('link-div', {
                'link-div-sticky': sticky,
              })}>
              <span className="menu-item" style={{ display: 'flex', alignItems: 'center' }}>
                <span style={activeTitle === menu.title ? { fontSize: 18, fontWeight: 'bold' } : {}}>{menu.title}</span>
              </span>
            </div>
          </a>
        ))}
      </div>
      <style jsx>{`
        .menu-container {
          div {
          }

          .highlight-div {
            background: rgb(230, 241, 246);
            @media screen and (prefers-color-scheme: dark) {
              background: #525252;
              color: #efefef;
            }
          }
        }
        .menu-container-header {
          display: flex;
          overflow: scroll;
          white-space: nowrap;
          margin-top: 10px;
          margin-bottom: 0px;
          padding-left: 10px;
          background: rgba(0, 0, 0, 0.6);
          height: 35px;
          -ms-overflow-style: none;
          scrollbar-width: none;
          @media screen and (max-width: 576px) {
            padding-left: 0px;
          }
        }
        .menu-container-header::-webkit-scrollbar {
          display: none;
        }
        .menu-container-sticky {
          display: flex;
          overflow: scroll;
          white-space: nowrap;
          margin-top: 10px;
          margin-bottom: 0px;
          padding-left: 5px;
          background: #000;
          height: 30px;
          -ms-overflow-style: none;
          scrollbar-width: none;

          @media screen and (min-width: 576px) {
            justify-content: center;
          }

          @media screen and (prefers-color-scheme: dark) {
            background: var(--dark-bg-color);
          }
        }
        .menu-container-sticky::-webkit-scrollbar {
          display: none;
        }
        .link-div {
          position: relative;
          color: #efefef;
          height: 30px;
          border-radius: 5px;
          display: flex;
          font-size: 0.65rem;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          padding: 0 5px;

          span {
            color: #f3f3f3;
            font-size: 15px;
          }
        }
        .link-div-sticky {
          margin: 0 2px;
          @media screen and (min-width: 576px) {
            padding: 0 10px;
            margin: 0 5px;
          }
        }
        .new {
          position: absolute;
          right: 0px;
          top: -8px;
          height: 30px;
          z-index: 10;
        }
        .menu-item {
          display: flex;
          align-items: center;
          color: rgba(0, 0, 0, 0.65);
          font-weight: 500;
          @media screen and (prefers-color-scheme: dark) {
            color: #efefef;
          }
        }
        @media screen and (max-width: 576px) {
          .menu-item {
            font-size: 0.65rem;
          }
          .new {
            right: -5px;
            top: -8px;
            height: 23px;
          }
        }
      `}</style>
    </>
  );
};
